import { normalizedClient } from '$lib/graphql/makeNormalizedClient'
import { CurrentUserDocument, type CurrentUserQuery } from '$lib/queries/generated/currentUser'
import type { CurrentUser } from '$lib/stores/auth-state'
import modal from '$lib/stores/modal-state'
import authState from '$lib/stores/auth-state'
import type { User, UserCredential } from 'firebase/auth'
import { alias, identify } from '../utils/track'
import { getIdTokenWithExpiresAt, isTokenInvalid } from './helpers'

export function isCredential(thing: unknown): thing is UserCredential {
	return thing != null && (thing as UserCredential).user != null
}

// handleSignInResult handles auth change events, and updates the store with the current user
export const handleSignInResult = async (
	credential: UserCredential | User,
): Promise<CurrentUser> => {
	let user: User
	if (isCredential(credential)) {
		user = credential.user
	} else {
		user = credential
	}

	if (user == null) {
		throw new Error('User is null')
	}

	const currentUser = await authState.loadCurrentUser(user)

	const [token, expiresAt] = await getIdTokenWithExpiresAt()
	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
	authState.setAuthToken(token, expiresAt, timezone)

	if (currentUser && !isTokenInvalid(token, expiresAt)) {
		// Do this async so that we don't block the UI
		normalizedClient
			.query<CurrentUserQuery>(CurrentUserDocument, {})
			.toPromise()
			.then(({ data, error }) => {
				// if (error == null && data?.currentUser) setupIntercomInfo(data.currentUser)
				if (data?.currentUser == null) return
				authState.setCurrentUser(data.currentUser)

				identify(data.currentUser.id, {
					email: data.currentUser.email,
					fullName: data.currentUser.currentName ?? '',
					hasSellerId: data.currentUser.hasSellerId,
				})
				const userId = user.uid
				alias(data.currentUser.id, userId)
			})

		await authState.resolveAuthCallbacks(null)
		modal.close()
		console.info(`[AUTH] Signed in`)
		return currentUser
	} else {
		authState.clearCurrentUser()
		authState.clearAuthToken()
		// setupIntercomInfo(null)
		console.error(`[AUTH] Failed to sign in`)
		await authState.resolveAuthCallbacks(new Error('Authentication failed'))
		return Promise.reject()
	}
}

// export function setupIntercomInfo(currentUser?: CurrentUserQuery['currentUser']) {
// 	console.log('[AUTH] Setting up intercom info')

// 	if (currentUser) {
// 		window.intercomSettings = {
// 			...currentUser.intercomSettings,
// 			hide_default_launcher: true,
// 		}
// 	} else {
// 		window.intercomSettings = {
// 			hide_default_launcher: true,
// 		}
// 	}
// }
