import { goto } from '$app/navigation'
import authState from '$stores/auth-state'
import { deleteApp, initializeApp } from 'firebase/app'
import {
	AuthErrorCodes,
	browserLocalPersistence,
	browserPopupRedirectResolver,
	getRedirectResult,
	GoogleAuthProvider,
	indexedDBLocalPersistence,
	initializeAuth,
	isSignInWithEmailLink,
	onAuthStateChanged,
	sendSignInLinkToEmail,
	setPersistence,
	signInWithCredential,
	signInWithEmailLink,
	signInWithPopup,
	signInWithRedirect,
	type Auth,
	type AuthError,
	type User,
	type UserCredential,
	createUserWithEmailAndPassword,
	updatePassword,
	signInWithEmailAndPassword,
	getAuth,
} from 'firebase/auth'
import { once } from 'lodash-es'
import { clientCredentials } from './config'
import { handleSignInResult } from './result'
import { track } from '../utils/track'

const firebaseApp = initializeApp(clientCredentials, {
	automaticDataCollectionEnabled: false,
	name: 'airheart-app',
})

const getAuthWithUser = once(function init() {
	if (typeof window === 'undefined') return null

	const newAuth = initializeAuth(firebaseApp, {
		persistence: [indexedDBLocalPersistence, browserLocalPersistence],
		popupRedirectResolver: null,
	})

	// Check if we're loading immediately after sign-in from redirect
	getRedirectResult(newAuth, browserPopupRedirectResolver)
		.then((cred) => {
			if (cred) {
				return handleSignInResult(cred)
			}
		})
		.catch(handleFirebaseError)

	onAuthStateChanged(
		newAuth,
		async (user) => {
			if (user) {
				await handleSignInResult(user)
			} else {
				authState.clearCurrentUser()
				authState.clearAuthToken()
				authState.setInitialized()
			}
		},
		(err) => {
			console.error('Firebase.onAuthStateChanged error', err)
			authState.clearCurrentUser()
			authState.clearAuthToken()
			reportError(err)
		},
	)

	return newAuth
})

const auth: Auth = getAuthWithUser()

async function currentUser() {
	const auth = getAuthWithUser()
	return new Promise<User>((resolve, reject) => {
		const unsub = onAuthStateChanged(
			auth,
			(user) => {
				resolve(user)
				unsub()
			},
			() => {
				reject()
				unsub()
			},
		)
	})
}

export {
	getAuth as getAuthWithUser,
	currentUser,
	auth,
	signInWithGoogle,
	handleGoogleYoloCredential,
	signInEAndP,
	signUpEAndP,
	updateUserPassword,
	signInOneTimePassword,
	handleOTPSignIn,
	signOut,
	destroyFirebaseApp,
}

async function signInWithGoogle(): Promise<UserCredential | void> {
	console.log('signInWithGoogle')
	// Clear any previous authentication errors from the application state
	authState.clearAuthError()

	// Create a new instance of the GoogleAuthProvider class
	const google = new GoogleAuthProvider()

	// Set persistence to use browser's local storage
	return setPersistence(auth, browserLocalPersistence).then(async () => {
		try {
			// For other browsers, try popup first
			console.log('signInWithGoogle - trying popup')
			const credential = await signInWithPopup(auth, google, browserPopupRedirectResolver)
			return handleSignInResult(credential)
		} catch (err) {
			if (
				err.code === AuthErrorCodes.POPUP_BLOCKED ||
				err.code === AuthErrorCodes.POPUP_CLOSED_BY_USER
			) {
				console.log('Google popup closed or blocked, trying redirect')
				// Fallback to redirect method for all browsers if popup fails
				await signInWithRedirect(auth, google)
				// The result will be handled when the page reloads
				return
			} else {
				// Handle all other errors
				return handleFirebaseError(err)
			}
		}
	})
}

type YoloCredential = {
	credential: string
	selected_by: string
	clientId: string
}

function handleFirebaseError(err: AuthError) {
	console.error('Firebase error', err, err?.code, err?.message, err?.stack)

	switch (err?.code) {
		case AuthErrorCodes.POPUP_BLOCKED:
			authState.setAuthError(
				"We're sorry, but you've blocked our popup. Please allow popups for this site.",
			)
			break
		case AuthErrorCodes.POPUP_CLOSED_BY_USER:
			authState.setAuthError(
				"We're sorry, but you've closed the popup before signing-in. Please try again.",
			)
			break
		case AuthErrorCodes.INTERNAL_ERROR:
			authState.setAuthError("We're sorry, but there was an internal error. Please try again.")
			break
		case AuthErrorCodes.NETWORK_REQUEST_FAILED:
			authState.setAuthError("We're sorry, but there was a network error. Please try again.")
			break

		case AuthErrorCodes.EXPIRED_POPUP_REQUEST:
			authState.setAuthError("We're sorry, but the sign-in request has expired, please try again.")
			break

		case AuthErrorCodes.INVALID_EMAIL:
			authState.setAuthError("We're sorry, but that email is invalid. Please try again.")
			break

		case AuthErrorCodes.CODE_EXPIRED:
			authState.setAuthError(
				'We are sorry, but your code has expired. Please sign in again to get a fresh code.',
			)
			break
		case AuthErrorCodes.EMAIL_EXISTS:
			authState.setAuthError(
				'We are sorry, but your email is already in use. Try Logging in or Continuing with Google.',
			)
			break
		case AuthErrorCodes.INVALID_PASSWORD:
			authState.setAuthError('We are sorry, but your email or password is incorrect.')
			break
		default:
			authState.setAuthError(
				'We are sorry, but there was an authentication error, please try again.',
			)
			break
	}
	return null
}

// handleGoogleYoloCredential handles sign in from Google Seamless Auth.
async function handleGoogleYoloCredential(result: YoloCredential): Promise<User> {
	track('GoogleYolo Authentication Returned')
	const credential = GoogleAuthProvider.credential(result.credential)
	return signInWithCredential(auth, credential)
		.then(handleSignInResult)
		.then((user) => {
			track('GoogleYolo Authentication Completed')
			return user
		})
		.catch((err) => {
			track('GoogleYolo Authentication Failed', { error: err })
			return null
		})
}

async function signUpEAndP(email: string, password: string): Promise<any> {
	track('EmailAndPassword Authentication - SignUp')
	authState.clearAuthError()
	return createUserWithEmailAndPassword(auth, email, password)
		.then(handleSignInResult)
		.then((user) => {
			track('EmailAndPassword Authentication Completed')
			return user
		})
		.catch(handleFirebaseError)
}

async function signInEAndP(email: string, password: string): Promise<any> {
	track('EmailAndPassword Authentication - SignIn')
	authState.clearAuthError()
	return signInWithEmailAndPassword(auth, email, password)
		.then(handleSignInResult)
		.then((user) => {
			track('EmailAndPassword Authentication Completed')
			return user
		})
		.catch(handleFirebaseError)
}

async function updateUserPassword(user: User, newPassword: string): Promise<any> {
	return updatePassword(user, newPassword)
		.then(() => {
			// Update successful.
			track('Authentication Update Password Successful')
			return true
		})
		.catch((error) => {
			const errorCode = error.code
			const errorMessage = error.message
			track('Authentication Update Password Failed', { errorMessage, errorCode })
			return errorMessage
		})
}

async function signInOneTimePassword(email: string, redirectUrl: string): Promise<void> {
	authState.clearAuthError()
	const returnURL = otpSignupSettings(redirectUrl, email)
	console.log('Sending sign-in link to:', email, 'with redirect:', redirectUrl)

	return sendSignInLinkToEmail(auth, email, returnURL)
		.catch(reportError)
		.then(void 0)
}

const handleOTPSignIn = (
	setShowEmailConfirmation: Dispatch<boolean>,
	emailValue?: string,
): Promise<any> => {
	// Confirm the link is a sign-in with email link.
	if (isSignInWithEmailLink(auth, window.location.href)) {
		// Additional state parameters can also be passed via URL.
		// This can be used to continue the user's intended action before triggering
		// the sign-in operation.
		// Get the email if available. This should be available if the user completes
		// the flow on the same device where they started it.
		const url = new URL(window.location.href)
		const email = emailValue?.length > 0 ? emailValue : url.searchParams.get('email')
		if (!email) {
			// User opened the link on a different device. To prevent session fixation
			// attacks, ask the user to provide the associated email again. For example:
			setShowEmailConfirmation(true)

			return null
		}
		// The client SDK will parse the code from the link for you.
		const signInOperation = (emailToSignIn: string) => {
			return signInWithEmailLink(auth, emailToSignIn, window.location.href)
				.then((credential) => {
					// TODO: Clear email from URL

					// Clear email from storage.
					// window.localStorage.removeItem('emailForSignIn')
					// You can access the new user via credential.user
					// Additional user info profile not available via:
					// credential.additionalUserInfo.profile == null
					// You can check if the user is new or existing:
					// credential.additionalUserInfo.isNewUser
					setShowEmailConfirmation(false)
					return handleSignInResult(credential)
				})
				.catch((error: any) => {
					// Some error occurred, you can inspect the code: error.code
					// Common errors could be invalid email and invalid or expired OTPs.
					switch (error?.code) {
						case 'auth/expired-action-code':
							setShowEmailConfirmation(true)
							break

						case 'auth/invalid-email':
							// this can happen if a user attempts to log-in with one e-mail on this device
							// but then uses a sign-up link for a different e-mail account.
							// In that case, the e-mail attached to the OTP link does not match
							// what's in the 'emailForSignIn' item
							// this will allow them to confirm with correct e-mail.
							setShowEmailConfirmation(true)
							break

						case 'auth/invalid-action-code':
							console.error(error)

							return signInOneTimePassword(email, window.location.href).then(() =>
								setShowEmailConfirmation(false),
							)
						default:
							reportError(error)
							throw error
					}
				})
		}

		return signInOperation(email)
	}
}

export function cleanURLAndForwardParams() {
	// get rid of apiKey and other params, keeping pinOnLoad

	const url = new URL(window.location.href)
	const params = url.searchParams

	const strip = ['apiKey', 'mode', 'oobCode', 'continueUrl', 'lang']
	for (const param of strip) {
		params.delete(param)
	}

	url.search = params.toString()
	return goto(url.toString())
}

async function signOut() {
	auth?.signOut()
	authState.clearCurrentUser()
	authState.clearAuthToken()

	if (typeof window !== 'undefined') {
		// to support logging out from all windows
		window?.localStorage?.setItem('logout', new Date().valueOf().toString())
	}

	// Reload window to clear urql state
	if (typeof window != 'undefined') {
		location.reload()
	}
}

function destroyFirebaseApp() {
	deleteApp(firebaseApp)
}

const otpSignupSettings = (redirect: string, email: string) => {
	const url = new URL(redirect)
	url.searchParams.set('email', email)

	return {
		// URL you want to redirect back to. The domain (www.example.com) for this
		// URL must be in the authorized domains list in the Firebase Console.
		url: url.toString(),
		// This must be true.
		handleCodeInApp: true,
	}
}
