import type { Cache } from '@urql/exchange-graphcache'
import {
	MapViewDocument,
	type MapViewQuery,
	type MapViewQueryVariables,
} from '$lib/queries/generated/QueryMapView'
import {
	MediaConnectionDocument,
	type MediaConnectionQuery,
	type MediaConnectionQueryVariables,
} from '$lib/queries/generated/QueryMedia'
import type {
	AddToListMutation,
	AddToListMutationVariables,
	RemoveFromListMutation,
	RemoveFromListMutationVariables,
	UpdateBlockDetailsMutation,
	UpdateBlockDetailsMutationVariables,
} from '$lib/queries/generated/UpdateBlock'
import type {
	InsertCustomRegionBlockMutation,
	InsertCustomRegionBlockMutationVariables,
	InsertDayBlockMutation,
	InsertDayBlockMutationVariables,
	InsertDestinationBlockMutation,
	InsertDestinationBlockMutationVariables,
	InsertHighlightBlockMutation,
	InsertHighlightBlockMutationVariables,
	InsertListBlockMutation,
	InsertListBlockMutationVariables,
	InsertPinBlockMutation,
	InsertPinBlockMutationVariables,
	InsertPlaceBlockMutation,
	InsertSourceBlockMutation,
	InsertSourceBlockMutationVariables,
} from '$lib/queries/generated/InsertBlock'
import type { TripEdgeFragment } from '$lib/queries/fragments/generated/TripEdge'
import type {
	DeleteBlockMutation,
	DeleteBlockMutationVariables,
} from '$lib/queries/generated/deleteBlock'
import type {
	UpdateBlockPositionsMutation,
	UpdateBlockPositionsMutationVariables,
} from '$lib/queries/generated/UpdateBlockPositions'
import type {
	UpsertRouteBlockMutation,
	UpsertRouteBlockMutationVariables,
} from '$lib/queries/generated/UpsertRoute'
import type { RouteBlockFieldsFragment } from '$lib/queries/fragments/generated/RouteBlockFields'
import type { ExtendedBlockFieldsFragment } from '$lib/queries/generated/ExtendedBlockFields'
import {
	ItineraryDocument,
	type ItineraryQuery,
	type ItineraryQueryVariables,
} from '$lib/queries/generated/QueryItinerary'
import type {
	InsertRootBlockMutation,
	InsertRootBlockMutationVariables,
} from '$lib/queries/generated/InsertRootBlock'
import { cloneDeep } from 'lodash-es'
import type { BlockFieldsFragment } from '$lib/queries/fragments/generated/BlockFields'
import {
	TripBaseDocument,
	type TripBaseQuery,
	type TripBaseQueryVariables,
} from '$lib/queries/generated/QueryTrip'
import { BlockType } from '../enums'
import type { InsertRequirementsMutation } from '$lib/queries/generated/InsertRequirements'
import {
	HighlightBlocksDocument,
	type HighlightBlocksQuery,
	type HighlightBlocksQueryVariables,
} from '$lib/queries/generated/QueryHighlights'
import {
	BlockDocument,
	type BlockQuery,
	type BlockQueryVariables,
} from '$lib/queries/generated/QueryBlock'
import type { ListItemFieldsFragment } from '$lib/queries/fragments/generated/ListItemFields'
import type { TripBaseFieldsFragment } from '$lib/queries/fragments/generated/TripBaseFields'

// highlights query
// trip query
// trips query
// block query
// map query
export function addToListHandler(
	result: AddToListMutation,
	args: AddToListMutationVariables,
	cache: Cache,
) {
	const list = result?.addToList?.listItem
	const block = result?.addToList?.blockItem
	const trip = result?.addToList?.trip

	const defaultRootId = trip?.rootBlocks?.find((b) => b.position === 0)?.id

	updateItineraryQueryCache(cache, block)
	updateItineraryQueryCache(cache, list)

	updateBlockQueryCache(cache, block)
	// updateBlockQueryCache(cache, list)

	// add to map view
	if (block?.rootId == defaultRootId) {
		updateMapViewQueryCache(cache, args?.tripID, block, null)
		updateMapViewQueryCache(cache, args?.tripID, list, null)
	}
	updateMapViewQueryCache(cache, args?.tripID, block, block?.rootId)
	updateMapViewQueryCache(cache, args?.tripID, list, list?.rootId)
}

// highlights query
// trip query
// trips query
// block query
// map query
export function removeFromListHandler(
	result: RemoveFromListMutation,
	args: RemoveFromListMutationVariables,
	cache: Cache,
) {
	const list = result.removeFromList?.listItem
	const block = result?.removeFromList?.blockItem
	const trip = result?.removeFromList?.trip

	const defaultRootId = trip?.rootBlocks?.find((b) => b.position === 0)?.id

	updateItineraryQueryCache(cache, block)
	updateItineraryQueryCache(cache, list)

	updateBlockQueryCache(cache, block)
	// updateBlockQueryCache(cache, list)

	// add to map view
	if (block?.rootId == defaultRootId) {
		updateMapViewQueryCache(cache, args?.tripID, block, null)
		updateMapViewQueryCache(cache, args?.tripID, list, null)
	} else {
		updateMapViewQueryCache(cache, args?.tripID, block, block?.rootId)
		updateMapViewQueryCache(cache, args?.tripID, list, list?.rootId)
	}
}

// highlights query
// trip query
// trips query
// block query
// map query
export function insertPinBlockHandler(
	result: InsertPinBlockMutation,
	args: InsertPinBlockMutationVariables,
	cache: Cache,
) {
	const newBlocks = result?.insertPinBlock?.insertedBlocks
	const trip = result?.insertPinBlock?.trip

	const defaultRootId = trip?.rootBlocks?.find((b) => b.position === 0)?.id

	newBlocks?.forEach((newBlock) => {
		insertItineraryQueryCache(cache, newBlock, newBlock?.rootId, args?.tripID)

		newBlock?.blockAncestors?.forEach((ancestor) => {
			insertItineraryQueryCache(cache, newBlock, ancestor.id, args?.tripID)
		})

		// add to map view
		if (newBlock?.rootId == defaultRootId) {
			updateMapViewQueryCache(cache, args?.tripID, newBlock, null)
		}
		updateMapViewQueryCache(cache, args?.tripID, newBlock, newBlock?.rootId)
	})

	const parentBlocks = result?.insertPinBlock?.parentBlocks

	parentBlocks?.forEach((parentBlock) => {
		updateItineraryQueryCache(cache, parentBlock)
	})
}

export function insertPlaceBlockHandler(
	result: InsertPlaceBlockMutation,
	args: InsertPinBlockMutationVariables,
	cache: Cache,
) {
	const newBlocks = result?.insertPlaceBlock?.insertedBlocks
	const trip = result?.insertPlaceBlock?.trip

	const defaultRootId = trip?.rootBlocks?.find((b) => b.position === 0)?.id

	newBlocks.forEach((newBlock) => {
		insertItineraryQueryCache(cache, newBlock, newBlock?.rootId, args?.tripID)

		newBlock?.blockAncestors?.forEach((ancestor) => {
			insertItineraryQueryCache(cache, newBlock, ancestor.id, args?.tripID)
		})

		// add to map view
		if (newBlock?.rootId == defaultRootId) {
			updateMapViewQueryCache(cache, args?.tripID, newBlock, null)
		}
		updateMapViewQueryCache(cache, args?.tripID, newBlock, newBlock?.rootId)
	})

	const parentBlocks = result?.insertPlaceBlock?.parentBlocks

	parentBlocks.forEach((parentBlock) => {
		updateItineraryQueryCache(cache, parentBlock)
	})
}

export function insertListBlockHandler(
	result: InsertListBlockMutation,
	args: InsertListBlockMutationVariables,
	cache: Cache,
) {
	const newBlocks = result?.insertListBlock?.insertedBlocks
	const trip = result?.insertListBlock?.trip

	const defaultRootId = trip?.rootBlocks?.find((b) => b.position === 0)?.id

	newBlocks.forEach((newBlock) => {
		insertItineraryQueryCache(cache, newBlock, newBlock?.rootId, args?.tripID)

		newBlock?.blockAncestors?.forEach((ancestor) => {
			insertItineraryQueryCache(cache, newBlock, ancestor.id, args?.tripID)
		})

		// add to map view
		if (newBlock?.rootId == defaultRootId) {
			updateMapViewQueryCache(cache, args?.tripID, newBlock, null)
		}
		updateMapViewQueryCache(cache, args?.tripID, newBlock, newBlock?.rootId)
	})
}

export function insertDestinationBlockHandler(
	result: InsertDestinationBlockMutation,
	args: InsertDestinationBlockMutationVariables,
	cache: Cache,
) {
	const newBlocks = result?.insertDestinationBlock?.insertedBlocks
	const trip = result?.insertDestinationBlock?.trip

	const defaultRootId = trip?.rootBlocks?.find((b) => b.position === 0)?.id

	newBlocks.forEach((newBlock) => {
		insertItineraryQueryCache(cache, newBlock, newBlock?.rootId, args?.tripID)

		newBlock?.blockAncestors?.forEach((ancestor) => {
			insertItineraryQueryCache(cache, newBlock, ancestor.id, args?.tripID)
		})

		// add to map view
		if (newBlock?.rootId == defaultRootId) {
			updateMapViewQueryCache(cache, args?.tripID, newBlock, null)
		}
		updateMapViewQueryCache(cache, args?.tripID, newBlock, newBlock?.rootId)
	})
}

export function updateBlockDetailsHandler(
	result: UpdateBlockDetailsMutation,
	args: UpdateBlockDetailsMutationVariables,
	cache: Cache,
) {
	const updatedBlocks = result.updateBlockDetails?.insertedBlocks
	updatedBlocks?.forEach((block) => {
		cache.updateQuery<BlockQuery, BlockQueryVariables>(
			{ query: BlockDocument, variables: { id: block.id } },
			(data) => {
				if (data) {
					return {
						...data,
						title: block.title,
						description: block.description,
					}
				}
				return data
			},
		)
	})
}

export function insertCustomRegionBlockHandler(
	result: InsertCustomRegionBlockMutation,
	args: InsertCustomRegionBlockMutationVariables,
	cache: Cache,
) {
	const newBlocks = result?.insertCustomRegionBlock?.insertedBlocks
	const trip = result?.insertCustomRegionBlock?.trip

	const defaultRootId = trip?.rootBlocks?.find((b) => b.position === 0)?.id

	newBlocks.forEach((newBlock) => {
		insertItineraryQueryCache(cache, newBlock, newBlock?.rootId, args?.tripID)

		newBlock?.blockAncestors?.forEach((ancestor) => {
			insertItineraryQueryCache(cache, newBlock, ancestor.id, args?.tripID)
		})

		// add to map view
		if (newBlock?.rootId == defaultRootId) {
			updateMapViewQueryCache(cache, args?.tripID, newBlock, null)
		}
		updateMapViewQueryCache(cache, args?.tripID, newBlock, newBlock?.rootId)
	})

	const parentBlocks = result?.insertCustomRegionBlock?.parentBlocks

	parentBlocks.forEach((parentBlock) => {
		updateItineraryQueryCache(cache, parentBlock)
	})
}

export function insertRequirementBlockHandler(
	result: InsertRequirementsMutation,
	args: InsertCustomRegionBlockMutationVariables,
	cache: Cache,
) {
	const newBlock = result?.insertRequirements

	updateItineraryQueryCache(cache, newBlock)

	// add to map view
	// updateMapViewQueryCache(cache, args?.tripID, newBlock, newBlock?.rootId)
}

// Updated insertCustomRegionBlockHandler
export function insertDayBlockHandler(
	result: InsertDayBlockMutation,
	args: InsertDayBlockMutationVariables,
	cache: Cache,
) {
	const newBlocks = result?.insertDayBlock?.insertedBlocks
	const trip = result?.insertDayBlock?.trip

	const defaultRootId = trip?.rootBlocks?.find((b) => b.position === 0)?.id

	newBlocks.forEach((newBlock) => {
		insertItineraryQueryCache(cache, newBlock, newBlock?.rootId, args?.tripID)

		newBlock?.blockAncestors?.forEach((ancestor) => {
			insertItineraryQueryCache(cache, newBlock, ancestor.id, args?.tripID)
		})

		// add to map view
		if (newBlock?.rootId == defaultRootId) {
			updateMapViewQueryCache(cache, args?.tripID, newBlock, null)
		}
		updateMapViewQueryCache(cache, args?.tripID, newBlock, newBlock?.rootId)
	})
}

export function insertHighlightBlockHandler(
	result: InsertHighlightBlockMutation,
	args: InsertHighlightBlockMutationVariables,
	cache: Cache,
) {
	const newBlocks = result?.insertHighlightBlock?.insertedBlocks
	const trip = result?.insertHighlightBlock?.trip

	const defaultRootId = trip?.rootBlocks?.find((b) => b.position === 0)?.id

	newBlocks.forEach((newBlock) => {
		insertHighlightQueryCache(cache, newBlock, newBlock?.rootId, args?.tripID)

		newBlock?.blockAncestors?.forEach((ancestor) => {
			insertHighlightQueryCache(cache, newBlock, ancestor.id, args?.tripID)
		})

		// add to map view
		if (newBlock?.rootId == defaultRootId) {
			updateMapViewQueryCache(cache, args?.tripID, newBlock, null)
		}
		updateMapViewQueryCache(cache, args?.tripID, newBlock, newBlock?.rootId)
		
		// Update media usageCount in cache
		if (newBlock?.highlight) {
			updateMediaUsageCountInCache(cache, newBlock.highlight.id, args?.tripID)
		}
	})

	const parentBlocks = result?.insertHighlightBlock?.parentBlocks

	parentBlocks.forEach((parentBlock) => {
		updateItineraryQueryCache(cache, parentBlock)
	})
}

export function insertSourceBlockHandler(
	result: InsertSourceBlockMutation,
	args: InsertSourceBlockMutationVariables,
	cache: Cache,
) {
	const newBlocks = result?.insertSourceBlock?.insertedBlocks
	const trip = result?.insertSourceBlock?.trip

	const defaultRootId = trip?.rootBlocks?.find((b) => b.position === 0)?.id

	newBlocks.forEach((newBlock) => {
		// add to map view
		if (newBlock?.rootId == defaultRootId) {
			updateMapViewQueryCache(cache, args?.tripID, newBlock, null)
		}
		updateMapViewQueryCache(cache, args?.tripID, newBlock, newBlock?.rootId)
	})

	const parentBlocks = result?.insertSourceBlock?.parentBlocks

	parentBlocks.forEach((parentBlock) => {
		updateItineraryQueryCache(cache, parentBlock)
	})
}

export function insertRootBlockHandler(
	result: InsertRootBlockMutation,
	args: InsertRootBlockMutationVariables,
	cache: Cache,
) {
	const newBlocks = result?.insertRootBlock?.insertedBlocks
	const trip = result?.insertRootBlock?.trip

	newBlocks.forEach((newBlock) => {
		const blockWithType = newBlock as unknown as ExtendedBlockFieldsFragment

		insertItineraryQueryCache(cache, blockWithType, blockWithType?.id, args?.tripID)

		// add to map view
		updateMapViewQueryCache(cache, args?.tripID, blockWithType, null)
		updateMapViewQueryCache(cache, args?.tripID, blockWithType, blockWithType?.rootId)
	})
}

export function upsertRouteBlockHandler(
	result: UpsertRouteBlockMutation,
	args: UpsertRouteBlockMutationVariables,
	cache: Cache,
) {
	const newBlocks = result?.upsertRouteBlock?.insertedBlocks
	const trip = result?.upsertRouteBlock?.trip

	const defaultRootId = trip?.rootBlocks?.find((b) => b.position === 0)?.id

	newBlocks.forEach((newBlock) => {
		const routeBlock = newBlock.routeAfter

		updateItineraryQueryCache(cache, newBlock)
		// add to map view
		if (routeBlock?.rootId == defaultRootId) {
			updateMapViewQueryCache(cache, args?.tripID, routeBlock, null)
		}
		updateMapViewQueryCache(cache, args?.tripID, routeBlock, routeBlock?.rootId)
	})
}

export function deleteBlockHandler(
	result: DeleteBlockMutation,
	args: DeleteBlockMutationVariables,
	cache: Cache,
) {
	const trip = result?.deleteBlock?.trip
	const parent = result?.deleteBlock?.parentBlock
	const deletedBlockIds = result?.deleteBlock?.deletedBlockIds

	// const defaultRootId = trip?.rootBlocks?.find((b) => b.position === 0)?.id

	if (parent != null) {
		updateItineraryQueryCache(cache, parent)
	}

	if (args?.blockType === BlockType.Highlight) {
		deletedBlockIds?.forEach((blockId) => {
			deleteFromHighlightsQueryCache(cache, blockId)
		})
	} else if (args?.blockType !== BlockType.Root) {
		deletedBlockIds?.forEach((blockId) => {
			deleteFromItineraryQueryCache(cache, blockId)
		})
	}
	deletedBlockIds?.forEach((id) => {
		cache.invalidate({
			__typename: 'Block',
			id: id as string,
		})
	})
}

export function updateBlockPositionsHandler(
	result: UpdateBlockPositionsMutation,
	args: UpdateBlockPositionsMutationVariables,
	cache: Cache,
) {
	result?.updateBlockPositions?.deletedRouteIds?.forEach((id) => {
		cache.invalidate({
			__typename: 'Block',
			id: id as string,
		})
	})

	result?.updateBlockPositions?.updatedBlocks?.forEach((newBlock) => {
		updateItineraryQueryCache(cache, newBlock)
	})

	// Group blocks by parentId to handle multiple updates to the same parent
	const blocksByParent = new Map<string, ExtendedBlockFieldsFragment[]>()
	result?.updateBlockPositions?.updatedBlocks?.forEach((block) => {
		if (block?.parentId) {
			const blocks = blocksByParent.get(block.parentId) || []
			blocks.push(block)
			blocksByParent.set(block.parentId, blocks)
		}
	})

	// Update each parent's Block query
	blocksByParent.forEach((blocks, parentId) => {
		// Update Block query
		cache.updateQuery<BlockQuery, BlockQueryVariables>(
			{ query: BlockDocument, variables: { id: parentId } },
			(data) => {
				if (data?.block) {
					// Get existing highlights that weren't updated
					const existingHighlights =
						data.block.highlights?.filter((h) => !blocks.some((b) => b.id === h.id)) || []

					// Combine existing and updated highlights
					const allHighlights = [...existingHighlights, ...blocks]

					// Sort by position
					const sortedHighlights = allHighlights.sort((a, b) => a.position - b.position)

					return {
						block: {
							...data.block,
							highlights: sortedHighlights,
						},
					}
				}
				return data
			},
		)

		// Update HighlightBlocks query
		cache.updateQuery<HighlightBlocksQuery, HighlightBlocksQueryVariables>(
			{
				query: HighlightBlocksDocument,
				variables: {
					blockId: parentId,
					tripId: args.tripID,
				},
			},
			(data) => {
				if (!data?.highlightConnection?.edges) return data

				// Get existing edges that weren't updated
				const existingEdges = data.highlightConnection.edges.filter(
					(edge) => !blocks.some((b) => b.id === edge.node.id),
				)

				// Create new edges for updated blocks, preserving existing edge data
				const updatedEdges = blocks.map((block) => {
					// Find existing edge for this block to preserve any fields we need
					const existingEdge = data.highlightConnection.edges.find((e) => e.node.id === block.id)

					return {
						__typename: 'BlockEdge' as const,
						cursor: existingEdge?.cursor || null,
						node: {
							...existingEdge?.node, // Preserve existing fields
							...block, // Override with updated fields
							__typename: 'Block' as const,
							highlightPosition: block.position, // Ensure highlightPosition matches position
						},
					}
				})

				// Combine and sort all edges by position
				const allEdges = [...existingEdges, ...updatedEdges].sort(
					(a, b) => a.node.position - b.node.position,
				)

				return {
					...data,
					highlightConnection: {
						...data.highlightConnection,
						edges: allEdges,
					},
				}
			},
		)
	})
}

function updateMapViewQueryCache(
	cache: Cache,
	tripId: string,
	newBlock: ExtendedBlockFieldsFragment | RouteBlockFieldsFragment,
	itineraryId: string,
) {
	cache.updateQuery<MapViewQuery, MapViewQueryVariables>(
		{ query: MapViewDocument, variables: { id: tripId, itineraryId } },
		(data) => {
			if (data?.mapView) {
				// Check if the block already exists
				// const blockExists = data.mapView?.some((b) => b.id === newBlock.id) || false

				return {
					mapView: {
						...data?.mapView?.filter((b) => b.id !== newBlock?.id),
						block: newBlock,
					},
				}
			}
			return data
		},
	)
}

function updateTripBaseQueryCache(
	cache: Cache,
	tripId: string,
	newBlock: ExtendedBlockFieldsFragment,
) {
	cache.updateQuery<TripBaseQuery, TripBaseQueryVariables>(
		{ query: TripBaseDocument, variables: { tripId } },
		(data) => {
			if (data?.trip) {
				return {
					trip: {
						...data?.trip,
						rootBlocks: [...data.trip.rootBlocks?.filter((b) => b.id != newBlock?.id), newBlock],
					},
				}
			}
			return data
		},
	)
}

export function ensureHighlightConnection(data: HighlightBlocksQuery | null): HighlightBlocksQuery {
	const newData = data ? cloneDeep(data) : ({ __typename: 'Query' } as HighlightBlocksQuery)
	if (newData.highlightConnection == null) {
		newData.highlightConnection = {
			edges: [],
			totalCount: 0,
			pageInfo: {
				__typename: 'PageInfo',
				hasNextPage: true,
				hasPreviousPage: false,
				startCursor: null,
				endCursor: null,
			},
			__typename: 'BlockConnection',
		}
	}
	return newData
}
export function ensureItineraryConnection(data: ItineraryQuery | null): ItineraryQuery {
	const newData = data ? cloneDeep(data) : ({ __typename: 'Query' } as ItineraryQuery)
	if (newData.itineraryConnection == null) {
		newData.itineraryConnection = {
			edges: [],
			totalCount: 0,
			pageInfo: {
				__typename: 'PageInfo',
				hasNextPage: true,
				hasPreviousPage: false,
				startCursor: null,
				endCursor: null,
			},
			__typename: 'BlockConnection',
		}
	}
	return newData
}

// Common types and interfaces
type QueryVariables = ItineraryQueryVariables | HighlightBlocksQueryVariables
type QueryData = ItineraryQuery | HighlightBlocksQuery
type ConnectionType = 'itineraryConnection' | 'highlightConnection'

interface SortFieldsOptions {
	connectionType: ConnectionType
	cache: Cache
}

// Helper functions
function sortCacheFields(options: SortFieldsOptions) {
	const { connectionType, cache } = options
	const fields = cache.inspectFields('Query').filter((field) => field.fieldName === connectionType)

	const sortedFields = [...fields].sort((a, b) => {
		const afterA = (a.arguments as QueryVariables)?.after ?? ''
		const afterB = (b.arguments as QueryVariables)?.after ?? ''
		return afterB.localeCompare(afterA)
	})

	const latestField = sortedFields[0]
	const latestCursor = (latestField?.arguments as QueryVariables)?.after

	return { sortedFields, latestField, latestCursor }
}

function createNewEdge(
	newBlock: ExtendedBlockFieldsFragment | BlockFieldsFragment,
	cursor: string | null,
) {
	return {
		cursor,
		__typename: 'BlockEdge' as const, // Use literal type
		node: {
			...newBlock,
			__typename: 'Block' as const, // Ensure node __typename is also correct
		},
	}
}

function updatePageInfo(pageInfo: any, latestCursor: string | null, isLatestField: boolean) {
	if (!isLatestField) return pageInfo

	return {
		...pageInfo,
		endCursor: latestCursor ?? pageInfo.endCursor,
	}
}

export function updateBlockQueryCache(
	cache: Cache,
	newBlock: ExtendedBlockFieldsFragment | ListItemFieldsFragment,
) {
	cache.updateQuery<BlockQuery, BlockQueryVariables>(
		{ query: BlockDocument, variables: { id: newBlock?.id } },
		// @ts-ignore
		(data) => {
			if (!data) return data

			return {
				block: {
					...data,
					...newBlock,
				},
			}
		},
	)
}

// Main cache update methods
export function updateItineraryQueryCache(
	cache: Cache,
	newBlock: ExtendedBlockFieldsFragment | BlockFieldsFragment,
) {
	const { sortedFields, latestField, latestCursor } = sortCacheFields({
		connectionType: 'itineraryConnection',
		cache,
	})

	sortedFields.forEach((field) => {
		cache.updateQuery<ItineraryQuery, ItineraryQueryVariables>(
			{
				query: ItineraryDocument,
				variables: field.arguments as ItineraryQueryVariables,
			},
			(data) => {
				if (!data?.itineraryConnection?.edges) return data

				const edges = data.itineraryConnection.edges
				const existingEdge = edges?.find((edge) => edge.node?.id === newBlock?.id)

				// console.log('existing edge', existingEdge)

				if (existingEdge) {
					const newEdge = {
						...existingEdge,
						cursor: latestCursor ?? existingEdge.cursor,
						node: {
							...newBlock,
							__typename: existingEdge.node.__typename,
						},
					}

					const updatedEdges = edges.map((edge) => (edge.node.id === newBlock.id ? newEdge : edge))

					return {
						...data,
						itineraryConnection: {
							...data.itineraryConnection,
							edges: updatedEdges,
							pageInfo: updatePageInfo(
								data.itineraryConnection.pageInfo,
								latestCursor,
								field === latestField,
							),
						},
					}
				}

				return data
			},
		)
	})
}

export function insertItineraryQueryCache(
	cache: Cache,
	newBlock: ExtendedBlockFieldsFragment | BlockFieldsFragment,
	itineraryId: string,
	tripId: string,
) {
	const { sortedFields } = sortCacheFields({
		connectionType: 'itineraryConnection',
		cache,
	})

	// Only update the first page (usually the one without 'after' cursor)
	const firstPageField = sortedFields.find((field) => {
		const args = field.arguments as ItineraryQueryVariables
		return !args?.after && args?.itineraryId === itineraryId && args?.tripId === tripId
	})

	if (!firstPageField) return

	cache.updateQuery<ItineraryQuery, ItineraryQueryVariables>(
		{
			query: ItineraryDocument,
			variables: firstPageField.arguments as ItineraryQueryVariables,
		},
		(data) => {
			data = ensureItineraryConnection(data)
			if (data.itineraryConnection) {
				const newEdge = createNewEdge(newBlock, null) // Use null cursor for first page

				// Get the page size from the original query variables
				// const pageSize = (firstPageField.arguments as ItineraryQueryVariables).first || 30

				data.itineraryConnection.edges = [
					newEdge,
					...data.itineraryConnection.edges?.filter((e) => e.node.id !== newBlock.id),
					// .slice(0, pageSize - 1), // Keep only up to pageSize - 1 existing items
				]

				// Increment total count if this is a new item
				if (!data.itineraryConnection.edges?.some((e) => e.node.id === newBlock.id)) {
					data.itineraryConnection.totalCount = (data.itineraryConnection.totalCount || 0) + 1
				}
			}
			return data
		},
	)
}

export function insertHighlightQueryCache(
	cache: Cache,
	newBlock: ExtendedBlockFieldsFragment,
	itineraryId: string,
	tripId: string,
) {
	const { sortedFields } = sortCacheFields({
		connectionType: 'highlightConnection',
		cache,
	})

	// Only update the first page
	const firstPageField = sortedFields.find((field) => {
		const args = field.arguments as HighlightBlocksQueryVariables
		return !args?.after && args?.blockId === itineraryId && args?.tripId === tripId
	})

	if (!firstPageField) return

	cache.updateQuery<HighlightBlocksQuery, HighlightBlocksQueryVariables>(
		{
			query: HighlightBlocksDocument,
			variables: firstPageField.arguments as HighlightBlocksQueryVariables,
		},
		(data) => {
			data = ensureHighlightConnection(data)
			if (data.highlightConnection) {
				const newEdge = createNewEdge(newBlock, null)

				// Get page size from original query
				// const pageSize = (firstPageField.arguments as HighlightBlocksQueryVariables).first || 20

				data.highlightConnection.edges = [
					// @ts-ignore
					newEdge,
					...data.highlightConnection.edges?.filter((e) => e.node.id !== newBlock.id),
					// .slice(0, pageSize - 1),
				]

				// Update total count for new items
				if (!data.highlightConnection.edges?.some((e) => e.node.id === newBlock.id)) {
					data.highlightConnection.totalCount = (data.highlightConnection.totalCount || 0) + 1
				}
			}
			return data
		},
	)
}
export function deleteFromItineraryQueryCache(cache: Cache, deletedBlockId: string) {
	const { sortedFields, latestField, latestCursor } = sortCacheFields({
		connectionType: 'itineraryConnection',
		cache,
	})

	sortedFields.forEach((field) => {
		cache.updateQuery<ItineraryQuery, ItineraryQueryVariables>(
			{
				query: ItineraryDocument,
				variables: field.arguments as ItineraryQueryVariables,
			},
			(data) => {
				data = ensureItineraryConnection(data)
				data.itineraryConnection.edges = data.itineraryConnection.edges?.filter(
					(e) => e.node.id !== deletedBlockId,
				)
				data.itineraryConnection.pageInfo = updatePageInfo(
					data.itineraryConnection.pageInfo,
					latestCursor,
					field === latestField,
				)
				return data
			},
		)
	})
}

export function deleteFromHighlightsQueryCache(cache: Cache, deletedBlockId: string) {
	const { sortedFields, latestField, latestCursor } = sortCacheFields({
		connectionType: 'highlightConnection',
		cache,
	})

	sortedFields.forEach((field) => {
		cache.updateQuery<HighlightBlocksQuery, HighlightBlocksQueryVariables>(
			{
				query: HighlightBlocksDocument,
				variables: field.arguments as HighlightBlocksQueryVariables,
			},
			(data) => {
				data = ensureHighlightConnection(data)
				data.highlightConnection.edges = data.highlightConnection.edges?.filter(
					(e) => e.node.id !== deletedBlockId,
				)
				data.highlightConnection.pageInfo = updatePageInfo(
					data.highlightConnection.pageInfo,
					latestCursor,
					field === latestField,
				)
				return data
			},
		)
	})
}
// Helper function to update media usageCount in cache
function updateMediaUsageCountInCache(cache: Cache, mediaId: string, tripId: string) {
	// Find all media connection queries in the cache
	const mediaFields = cache.inspectFields('Query').filter((field) => 
		field.fieldName === 'mediaConnection'
	)
	
	// Update each media connection query
	mediaFields.forEach((field) => {
		cache.updateQuery<MediaConnectionQuery, MediaConnectionQueryVariables>(
			{
				query: MediaConnectionDocument,
				variables: field.arguments as MediaConnectionQueryVariables,
			},
			(data) => {
				if (!data?.mediaConnection?.edges) return data
				
				// Find the media item in the connection
				const edges = data.mediaConnection.edges
				const mediaEdgeIndex = edges.findIndex((edge) => edge.node.id === mediaId)
				
				if (mediaEdgeIndex >= 0) {
					// Create a new array with the updated media item
					const newEdges = [...edges]
					newEdges[mediaEdgeIndex] = {
						...edges[mediaEdgeIndex],
						node: {
							...edges[mediaEdgeIndex].node,
							usageCount: (edges[mediaEdgeIndex].node.usageCount || 0) + 1
						}
					}
					
					return {
						...data,
						mediaConnection: {
							...data.mediaConnection,
							edges: newEdges
						}
					}
				}
				
				return data
			}
		)
	})
}
