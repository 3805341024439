import { goto } from '$app/navigation'
import type { SignInState } from '$lib/stores/auth-state'
import authState from '$lib/stores/auth-state'
import modal from '$lib/stores/modal-state'
import { auth, currentUser } from './firebase'

export const WaitlistCount = 100_000

// export function getWaitlistPos(user: CurrentUserQuery['currentUser']) {
//   const thisDate = new Date('2022-06-03T15:47:05+0000')
//   const waitlistedDate = new Date(user?.waitListedAt.toString())

//   const newPos =
//     waitlistedDate >= thisDate
//       ? formatNumber(user?.waitListPosition + 22000)
//       : formatNumber(user?.waitListPosition)

//   return newPos
// }

export function isTokenInvalid(token: string, expiresAt: number) {
	return !token || expiresAt < Date.now()
}

export async function performSignIn(intent: SignInState = 'signUp'): Promise<void> {
	const completion = new Promise<void>((yeah, nah) => {
		modal.open('signup', {
			intent,
			callback: (err) => {
				if (err) {
					nah(err)
				} else {
					yeah()
				}
			},
		})
	})
	return completion
}

export const getRedirectURLParam = () => {
	const urlParams = new URLSearchParams(window.location.search)
	return urlParams.get('redirect') ? decodeURIComponent(urlParams.get('redirect')) : null
}

export const afterSignInRedirect = () => {
	const nextLocation = getRedirectURLParam()
	console.log(`[AUTH] Return to: ${nextLocation}`)

	if (window.location.pathname === nextLocation || nextLocation == null) {
		console.log('[AUTH] pushing /trips')
		return goto('/trips', { keepFocus: false })
	} else if (nextLocation.startsWith('/')) {
		console.log(`[AUTH] pushing: ${nextLocation}`)
		return goto(nextLocation, { keepFocus: false })
	}
}

export async function getIdToken(forceRefresh = false): Promise<string> {
	if (auth?.currentUser == null) return null
	return auth.currentUser.getIdToken(forceRefresh)
}

export async function getIdTokenWithExpiresAt(forceRefresh?: boolean): Promise<[string, number]> {
	const user = await currentUser()
	// console.log('Get Token User Is: ', user)

	if (user == null) return [null, 0]
	const idToken = await user.getIdTokenResult(forceRefresh)

	return [idToken.token, new Date(idToken.expirationTime).getTime()]
}

export const isLoggedIn = () => auth?.currentUser != null

export const signOut = (redirect?: boolean) => {
	auth?.signOut()
	authState?.clearAuthToken()
	authState?.clearCurrentUser()

	if (typeof window !== 'undefined') {
		// to support logging out from all windows
		window?.localStorage?.setItem('logout', new Date().valueOf().toString())
	}
}

export const clearClientSession = signOut

export function formatNumber(number: number): string {
	return number.toLocaleString(undefined, {
		maximumFractionDigits: 0,
	})
}
