// import type { RemoveRoutePinMutation } from '$lib/queries/generated/RemoveRoutePin';
import type {
	CacheExchangeOpts,
	DataFields,
	ResolveInfo,
	Variables,
} from '@urql/exchange-graphcache'
import {
	deleteTripHandler,
	duplicateTripHandler,
	insertMediaHandler,
	insertTripHandler,
	makePurchaseHandler,
	publishListingHandler,
	unpublishListingHandler,
	updateTripHandler,
} from './cache/trips'
import {
	addToListHandler,
	deleteBlockHandler,
	insertCustomRegionBlockHandler,
	insertDayBlockHandler,
	insertDestinationBlockHandler,
	insertHighlightBlockHandler,
	insertListBlockHandler,
	insertPinBlockHandler,
	insertPlaceBlockHandler,
	insertRequirementBlockHandler,
	insertRootBlockHandler,
	insertSourceBlockHandler,
	removeFromListHandler,
	updateBlockDetailsHandler,
	updateBlockPositionsHandler,
	upsertRouteBlockHandler,
} from './cache/blocks'
import { ProfileFieldsFragmentDoc } from '$lib/queries/fragments/generated/ProfileFields'
import { fetchCouponCodesHandler } from './cache/store'
import { relayPagination } from '@urql/exchange-graphcache/extras'
import type {
	AddToListMutationVariables,
	UpdateBlockDetailsMutationVariables,
} from '$lib/queries/generated/UpdateBlock'
import {
	ExtendedBlockFieldsFragmentDoc,
	type ExtendedBlockFieldsFragment,
} from '$lib/queries/generated/ExtendedBlockFields'
import {
	ListItemFieldsFragmentDoc,
	type ListItemFieldsFragment,
} from '$lib/queries/fragments/generated/ListItemFields'
import {
	TripBaseFieldsFragmentDoc,
	type TripBaseFieldsFragment,
} from '$lib/queries/fragments/generated/TripBaseFields'
import type { UpdateTripMutationVariables } from '$lib/queries/generated/UpdateTrip'
import type { UpdateBlockPositionsMutationVariables } from '$lib/queries/generated/UpdateBlockPositions'
import { BlockFieldsFragmentDoc } from '$lib/queries/fragments/generated/BlockFields'
// import { insertRoutePinHandler, removeRoutePinHandler } from './cache/pinning';
// import { insertReservationHandler } from './cache/reservations';
// import type { MutationRemoveRoutePinArgs } from './types.d';

// returning null embeds the data directly into the cache without normalization
export const embed = () => null

function dateTimeResolver(parent: DataFields, args: Variables, _: any, info: ResolveInfo) {
	return parent[info.fieldName] != null ? new Date(parent[info.fieldName] as string) : null
}

export const normalizedCacheConfig: Partial<CacheExchangeOpts> = {
	// schema: schema,
	keys: {
		// PinnedRoute: (r) => r.path as string,
		// IntercomUserData: (r) => r.user_id as string,
		GooglePlaceResult: (r) => r.placeId as string,
		GooglePhoto: (r) => r.googleName as string,
		Venue: (r) => r.placeId as string,
		Location: embed,
		ListingConnection: () => null, // Don't cache listing connection
		HighlightConnection: () => null, // Don't cache highlight connection to prevent stale data
	},
	optimistic: {
		updateProfile: (args, cache, info) => {
			const profileFragment = cache.readFragment(ProfileFieldsFragmentDoc, { id: args.id })

			if (!profileFragment) return null
			return {
				...profileFragment,
				bio: args.bio,
			}
		},
		updateBlockDetails: (args: UpdateBlockDetailsMutationVariables, cache, info) => {
			const blockFragment = cache.readFragment(ExtendedBlockFieldsFragmentDoc, {
				id: args.id,
			}) as ExtendedBlockFieldsFragment

			const blockItem = {
				...blockFragment,
				description: args?.input?.description ?? blockFragment?.description,
				title: args?.input?.title ?? blockFragment?.title,
			}
			// console.log('blockItem', blockItem)
			return { updatedBlocks: [blockItem], trip: null }
		},
		updateTrip: (args: UpdateTripMutationVariables, cache, info) => {
			const tripFragment = cache.readFragment(TripBaseFieldsFragmentDoc, {
				id: args.id,
			}) as TripBaseFieldsFragment

			const tripItem = {
				...tripFragment,
				description: args?.input?.description ?? tripFragment.description,
				title: args?.input?.title ?? tripFragment.title,
			}
			return { updateTrip: tripItem }
		},
		deleteTrip: (args, cache, info) => {
			return { id: args.id }
		},
		deleteBlock: (args, cache, info) => {
			return { parentBlock: null, trip: null, deletedBlockIds: [args?.id] }
		},
		addToList: (args: AddToListMutationVariables, cache, info) => {
			const blockFragment = cache.readFragment(ExtendedBlockFieldsFragmentDoc, {
				id: args.blockID,
			}) as ExtendedBlockFieldsFragment
			const listFragment = cache.readFragment(ListItemFieldsFragmentDoc, {
				id: args.listID,
			}) as ListItemFieldsFragment
			const tripFragment = cache.readFragment(TripBaseFieldsFragmentDoc, {
				id: args.tripID,
			}) as TripBaseFieldsFragment

			if (!blockFragment || !listFragment || !tripFragment) return null
			const blockItem = {
				...blockFragment,
				parentId: args.listID,
				lists: blockFragment.lists ? blockFragment.lists.filter((b) => b.id != args.listID) : [],
				parentList: listFragment,
				parentBlock: listFragment,
			}
			const listItem = {
				...listFragment,
				childBlocks: listFragment.childBlocks
					? [...listFragment.childBlocks, blockItem]
					: [blockItem],
			}
			return { listItem, blockItem, trip: tripFragment }
		},
		removeFromList: (args, cache, info) => {
			const blockFragment = cache.readFragment(ExtendedBlockFieldsFragmentDoc, {
				id: args.blockID,
			}) as ExtendedBlockFieldsFragment
			const listFragment = cache.readFragment(ListItemFieldsFragmentDoc, {
				id: args.listID,
			}) as ListItemFieldsFragment
			const tripFragment = cache.readFragment(TripBaseFieldsFragmentDoc, {
				id: args.tripID,
			}) as TripBaseFieldsFragment

			if (!blockFragment || !listFragment || !tripFragment) return null
			const blockItem = {
				...blockFragment,
				parentId: null,
				lists: blockFragment.lists ? [listFragment, ...blockFragment.lists] : [listFragment],
				parentList: null,
				parentBlock: null,
			}
			const listItem = {
				...listFragment,
				childBlocks: listFragment.childBlocks
					? listFragment.childBlocks.filter((b) => b.id != args.blockID)
					: [],
			}

			return { listItem, blockItem, trip: tripFragment }
		},

		updateBlockPositions: (args: UpdateBlockPositionsMutationVariables, cache, info) => {
			let updatedBlocks: ExtendedBlockFieldsFragment[] = []

			// Ensure we have an array to work with, defaulting to empty array if undefined
			const inputs = Array.isArray(args.input) ? args.input : args.input ? [args.input] : []

			// Process each block update
			inputs.forEach((block) => {
				const blockFragment = cache.readFragment(BlockFieldsFragmentDoc, {
					id: block.id,
				}) as ExtendedBlockFieldsFragment

				if (blockFragment) {
					updatedBlocks.push({
						...blockFragment,
						position: block.position,
					})
				}
			})

			// console.log('updatedBlocks', updatedBlocks)

			return { updatedBlocks, deletedRouteIds: [] }
		},
		// insertPinBlock: (args: InsertPinBlockMutationVariables, cache, info) => {
		// 	console.log('insertPinBlock cache', args)
		// 	const pinBlock = {
		// 		__typename: 'Block',
		// 		blockType: BlockType.CustomPin,
		// 		icon: CustomIcon.Default,
		// 		color: CustomColor.Default,
		// 		title: args?.input?.title,
		// 		description: args?.input?.description,
		// 		parentId: args.input?.parentID,
		// 		rootId: args.input?.rootBlockId,
		// 	}

		// 	const parentFragment = cache.readFragment(ExtendedBlockFieldsFragmentDoc, {
		// 		id: args.input?.parentID,
		// 	}) as ExtendedBlockFieldsFragment

		// 	let parentBlocks: ExtendedBlockFieldsFragment[] = []
		// 	// if (!parentFragment) return null
		// 	// if (parentFragment.blockType === BlockType.Destination) {
		// 	// 	parentBlocks = [
		// 	// 		{
		// 	// 			...parentFragment,
		// 	// 			childBlocks: parentFragment?.childBlocks
		// 	// 				? [...parentFragment.childBlocks, pinBlock]
		// 	// 				: [pinBlock],
		// 	// 		} as ExtendedBlockFieldsFragment,
		// 	// 	]
		// 	// }

		// 	// console.log('parentItem', parentBlocks, parentFragment)

		// 	const tripFragment = cache.readFragment(TripBaseFieldsFragmentDoc, {
		// 		id: args.tripID,
		// 	}) as TripBaseFieldsFragment

		// 	return { insertedBlocks: [pinBlock], parentBlocks, trip: tripFragment }
		// },
	},

	updates: {
		Mutation: {
			deleteTrip: deleteTripHandler,
			updateTrip: updateTripHandler,
			insertRootBlock: insertRootBlockHandler,
			insertTrip: insertTripHandler,
			insertMedia: insertMediaHandler,
			duplicateTrip: duplicateTripHandler,
			makePurchase: makePurchaseHandler,
			publishListing: publishListingHandler,
			unpublishListing: unpublishListingHandler,
			updateBlockDetails: updateBlockDetailsHandler,
			addToList: addToListHandler,
			removeFromList: removeFromListHandler,
			insertPinBlock: insertPinBlockHandler,
			insertListBlock: insertListBlockHandler,
			insertPlaceBlock: insertPlaceBlockHandler,
			insertSourceBlock: insertSourceBlockHandler,
			insertDestinationBlock: insertDestinationBlockHandler,
			insertCustomRegionBlock: insertCustomRegionBlockHandler,
			insertHighlightBlock: insertHighlightBlockHandler,
			insertDayBlock: insertDayBlockHandler,
			insertRequirementBlock: insertRequirementBlockHandler,
			upsertRouteBlock: upsertRouteBlockHandler,
			deleteBlock: deleteBlockHandler,
			updateBlockPositions: updateBlockPositionsHandler,
			createCoupon: fetchCouponCodesHandler,
		},
	},
	resolvers: {
		Query: {
			itineraryConnection: relayPagination(),
			highlightConnection: relayPagination(), // Add relay pagination for highlight connection
		},
	},
}
