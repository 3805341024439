import { browser } from '$app/environment'

export const prod = import.meta.env.PROD
export const isDev = import.meta.env.DEV
export const isSSR = !browser

export const fetchOptions: RequestInit = {
	credentials: 'include', // Allows cookies/session sharing
	mode: isDev ? 'cors' : 'cors', // Explicitly set mode (never use 'no-cors' unless testing)
	keepalive: true, // Keeps request open if the tab is closing
	referrerPolicy: 'strict-origin-when-cross-origin', // More secure default
	headers: {
		'Content-Type': 'application/json', // Ensure correct data format
		Accept: 'application/json', // Accept only JSON responses
	},
}

export type ResetClientFunc = () => void
