import { authExchange } from '@urql/exchange-auth'
import { makeOperation } from '@urql/svelte'
import { getIdTokenWithExpiresAt, isTokenInvalid, signOut } from '$lib/authentication/helpers'
import { isAuthenticationError } from './error'
import authState from '$lib/stores/auth-state'

export type AuthState = {
	token: string
	expiresAt: number
	timezone: string
}

function isServer() {
	return typeof window === 'undefined'
}

// For an explanation of urql's auth flow, see
// https://formidable.com/open-source/urql/docs/advanced/authentication/
export const auth = authExchange(async (utilities) => {
	if (isServer()) return null
	const [token, expiresAt] = await getIdTokenWithExpiresAt()
	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
	authState.setAuthToken(token, expiresAt, timezone)

	return {
		addAuthToOperation(operation) {
			const [token, expiresAt] = authState.getAuthToken()
			if (isTokenInvalid(token, expiresAt)) {
				return operation
			}

			const fetchOptions =
				typeof operation.context.fetchOptions === 'function'
					? operation.context.fetchOptions()
					: operation.context.fetchOptions || {}

			return makeOperation(operation.kind, operation, {
				...operation.context,
				fetchOptions: {
					...fetchOptions,
					headers: {
						...fetchOptions.headers,
						Authorization: `Bearer ${token}`,
						'X-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
					},
				},
			})
		},
		didAuthError(error) {
			return isAuthenticationError(error)
		},
		willAuthError(operation) {
			// assumption: all operations used by the normalized client are user
			// specific and thus require a token for operation
			// If we ever want to query non-user specific operations, we'll need to
			// add the appropriate logic here
			const [token, expiresAt] = authState.getAuthToken()

			if (!token) {
				console.log('[Auth] No token, will auth error')
				return true
			}

			if (expiresAt != null) {
				if (expiresAt < Date.now()) {
					return true
				}
			}

			return false
		},
		async refreshAuth() {
			let [token, expiresAt] = authState.getAuthToken()
			if (!token) {
				const [newToken, newExpiresAt] = await getIdTokenWithExpiresAt()
				if (newToken) {
					const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
					console.log(
						`Authentication token expires in ${Math.round(
							(newExpiresAt - Date.now()) / 1000,
						)} seconds`,
					)
					authState.setAuthToken(newToken, newExpiresAt, timezone)
					return null
				}
				return null
			}

			const [newToken, newExpiresAt] = await getIdTokenWithExpiresAt(true)
			if (newToken) {
				console.log('token refreshed')
				const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
				authState.setAuthToken(newToken, newExpiresAt, timezone)
				return null
			}

			console.log('Signing out because token could not be refreshed')
			signOut()

			return null
		},
	}
})
